import React from 'react'
import CatalogCard from '../../../../../features/catalog/ui/CatalogCard'
const ExampleCatalog = () => {
  return (
    <>
        <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />
                 <CatalogCard name={"Загрузка..."} title={'Загрузка...'}
                 cost={'Загрузка...'} imageUrl={'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'} />

    </>

  )
}

export default ExampleCatalog
