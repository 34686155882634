import React from 'react'
import CatalogNavItem from '../../../../../features/catalog/ui/CatalogNavItem'
const ExampleCatalogNav = () => {
  return (
    <>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        <li>
            <CatalogNavItem title={'Загрузка...'} />
        </li>
        
    </>
  )
}

export default ExampleCatalogNav
